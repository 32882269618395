@import url("https://fonts.googleapis.com/css?family=Lato|Roboto&display=swap");

.App-header {
  background-color: #a47da4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
body {
  background: #a47da4;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 12pt;
}
.logo {
  width: 200px;
  height: auto;
  margin-bottom: 0px;
  margin-top: 10px;
}
span {
  border-bottom: 2px solid #d5cfe1;
  font-weight: bold;
}
span:hover {
  border-bottom: 0px;
}
.intro {
  text-align: center;
}
.Why {
  width: 60%;
  margin: 0 auto;
  background-color: white;
  color: #a47da4;
  margin-top: 10px;
  border-radius: 2px;
  padding: 10px;
}
.figure {
  width: 250px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.info {
  background: rgb(121, 92, 121);
  padding: 10px;
  margin-top: -10px;
  padding-bottom: 20px;
  border-radius: 5px;
}
.figure-button {
  background-color: #a47da4;
  padding: 5px;
  border-radius: 5px;
}
.figure-button:hover {
  background-color: rgb(140, 103, 140);
}
a {
  color: #ffffff;
  text-decoration: none;
}
h2 {
  font-size: 18pt;
  text-align: center;
  padding-top: 10px;
  margin: 0px;
  font-family: "Roboto", sans-serif;
}
p {
  font-weight: normal;
  text-align: justify;
  margin-bottom: 30px;
}
.container {
  display: flex;
  justify-content: center;
}
.Profile {
  filter: grayscale(100%);
  object-fit: cover;
  width: 250px;
  height: 250px;
}
.Profile:hover {
  filter: grayscale(0%);
}
.footer {
  margin-top: 40px;
  margin-bottom: 40px;
}
.footer a {
  color: #ffffff;
  text-decoration: none;
  border-bottom: 2px solid #d5cfe1;
  font-weight: bold;
}
.footer a:hover {
  border-bottom: 0px;
}
.copyright {
  color: rgba(255, 255, 255, 0.845);
  text-align: center;
  font-size: 10pt;
}

/* Responsiveness */
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .container {
    flex-wrap: wrap;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .container {
    flex-wrap: wrap;
  }
}
